// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.button
  // Without secondary or primary style,
  // the button should not apply any styling.
  // This is to cater for special situations
  // where the button is used as a container.
  reset-button()

  &.primary, &.secondary, &.naked
    position: relative
    display: inline-flex
    transition: 80ms background ease-in-out, 80ms color ease-in-out, 80ms border-color ease-in-out, 80ms box-shadow ease-in-out
    border-radius: $br.m
    outline: 0
    cursor: pointer
    justify-content: center
    align-items: center
    gap: $cs.xxs
    height: $default-input-height
    text-decoration: none
    padding: 0 $cs.m
    white-space: nowrap
    box-sizing: border-box

    &.small
      height: 2rem
      padding: 0 $cs.s
      gap: $cs.xs3

    .icon
      margin-left: - $cs.xxs
      flex-shrink: 0

      &:only-child
        margin-right: - $cs.xxs

    &.with-alert:after
      content: ''
      position: absolute
      top: 4px
      left: 17px
      border: 3px solid var(--c-border-neutral-min)
      border-radius: 100%
      size: 13px
      box-sizing: border-box
      background-color: var(--c-bg-brand-normal)

    .expand-icon
      color: var(--c-icon-neutral-light)
      font-size: 1.285rem
      margin-left: .2rem
      margin-right: - $cs.xxs

    &.only-icon
      gap: 0
      padding: 0 $cs.s
      width: 2.5rem
      height: 2.5rem

    &.primary:not(.naked)
      color: var(--c-bg-neutral-min)
      background-color: var(--c-text-brand-normal)

      &:disabled
        background-color: var(--c-border-neutral-light)

      &:not(:disabled)
        &:hover
          background-color: var(--c-text-brand-normal-hover)

        +focus-visible()
          background-color: var(--c-text-brand-normal-hover)

        &:active
          background-color: var(--c-text-brand-normal-active)

      &.warning
        background-color: var(--c-bg-warning-normal)

        &:not(:disabled)
          &:hover
            background-color: var(--c-bg-warning-normal-hover)

          +focus-visible()
            background-color: var(--c-bg-warning-normal-hover)

          &:active
            background-color: var(--c-bg-warning-normal-active)

        &:disabled
          background-color: var(--c-bg-warning-normal-disabled)

      &.danger
        background-color: var(--c-bg-error-normal)

        &:not(:disabled)
          &:hover
            background-color: var(--c-bg-error-normal-hover)

          +focus-visible()
            background-color: var(--c-bg-error-normal-hover)

          &:active
            background-color: var(--c-bg-error-normal-active)

        &:disabled
          background-color: var(--c-bg-error-normal-disabled)

    &.secondary
      color: var(--c-text-neutral-heavy)
      background-color: var(--c-bg-neutral-min)
      box-shadow: 0px 2px 2px 0px rgba(0 0 0 4.5%), inset 0px 0px 0px 1px var(--c-bg-neutral-min)
      gradient-border(var(--c-bg-neutral-min), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))
      border: 1px solid transparent

      &:not(:disabled)
        &:hover
          gradient-border(var(--c-bg-neutral-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

        +focus-visible()
          gradient-border(var(--c-bg-neutral-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

        &:active
          gradient-border(var(--c-bg-neutral-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))
          box-shadow: none

      &.warning
        color: var(--c-text-warning-normal)
        border-color: var(--c-border-warning-normal)

        &:not(:disabled)
          &:hover
            gradient-border(var(--c-bg-warning-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

          +focus-visible()
            gradient-border(var(--c-bg-warning-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

          &:active
            gradient-border(var(--c-bg-warning-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))
            box-shadow: none

      &.danger
        color: var(--c-text-error-normal)
        border-color: var(--c-border-error-normal)

        &:not(:disabled)
          &:hover
            gradient-border(var(--c-bg-error-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

          +focus-visible()
            gradient-border(var(--c-bg-error-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

          &:active
            gradient-border(var(--c-bg-error-extralight), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))
            box-shadow: none

      &:disabled
        color: var(--c-text-neutral-extralight)
        background-color: var(--c-bg-neutral-semilight)
        border-color: var(--c-border-neutral-light)

      &.small
        margin: $cs.xxs
        border-radius: $br.s
        font-size: $fs.s

        .icon
          width: 1.2rem
          height: 1.2rem

      &:not(:disabled)
        &:hover
          gradient-border(var(--c-bg-neutral-min-hover), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

        +focus-visible()
          gradient-border(var(--c-bg-neutral-min-hover), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))

        &:active
          gradient-border(var(--c-bg-neutral-min-hover), var(--c-gradient-neutral-light-01), var(--c-gradient-neutral-light-02))
          box-shadow: none

    &.naked:not(.primary)
      color: var(--c-text-neutral-light)

      &:disabled
        color: var(--c-text-neutral-extralight)

      &:not(:disabled)
        &:hover
          background-color: rgba(0,0,0,.04)
          color: var(--c-text-neutral-heavy)

        +focus-visible()
          background-color: rgba(0,0,0,.04)
          color: var(--c-text-neutral-heavy)

        &:active
          background-color: var(--c-bg-neutral-semilight)

    &.primary.naked
      color: var(--c-bg-brand-normal)

      &:disabled
        color: var(--c-bg-brand-normal-disabled)

      &:not(:disabled)
        &:hover
          background-color: var(--c-bg-brand-semilight)
          color: var(--c-bg-brand-normal-hover)

        +focus-visible()
          background-color: var(--c-bg-brand-semilight)
          color: var(--c-bg-brand-normal-hover)

        &:active
          background-color: var(--c-bg-brand-semilight)
          color: var(--c-bg-brand-normal-hover)

      &.small
        border-radius: $br.s

      &.warning
        color: var(--c-text-warning-normal)

        &:hover
          color: var(--c-text-warning-normal-hover)

      &.danger
        color: var(--c-text-error-normal)

        &:hover
          color: var(--c-text-error-normal-hover)

    &.with-dropdown&.only-icon:not(&.no-dropdown-icon)
      width: fit-content

    &.with-icon
      line-height: 1.3

      &.responsive-label
        padding: $cs.xs

        +media-query($bp.xxs)
          .icon
            margin-left: 0
            margin-right: 0

          .link-button-message
            display: none

    &.error
      animation: shake .15s 4 linear

    &:disabled
      cursor: default

    &.busy:not(.naked),
    &.busy
      // stylelint-disable-next-line declaration-no-important
      color: transparent !important
      position: relative
      svg
        center-absolute()

      stop
        stop-color: $s
  .content
    position: relative

  .spinner
    position: absolute
    top: 0
    bottom: 0
    left: - $cs.s
    right: - $cs.s

.button-group
  display: flex
  flex-direction: column
  align-items: stretch
  gap: $cs.xs

  &.align-start
    justify-content: flex-start

  &.align-end
    justify-content: flex-end

  &.align-center
    justify-content: center

  +media-query-min($bp.sm)
    flex-direction: row
    align-items: center
